.credentials {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;

  gap: 10px 10px;
  padding-top: 20px;
}

.backgroundImage {
  opacity: 0.05;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  object-fit: fill;
  font-size: 500px;
  align-content: left;
  align-items: left;

  padding-left: 0px;
  padding-top: 0px;
}

.login {
  z-index: 10;
}

.adLogin {
  position: absolute;
  right: 20px;
  bottom: 130px;
}

.loginButton {
  background-color: dodgerblue;
  color: aliceblue;
  border-radius: 10%;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.loginButton:disabled {
  background-color: rgba(179, 183, 187, 0.308);
  color: rgba(57, 58, 59, 0.336);
  border-radius: 10%;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
}
