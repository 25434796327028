.main {
  justify-content: center;
  align-items: center;
  display: flex;
}

input[type='text'] {
  font-size: 16px;
}

input[type='password'] {
  font-size: 16px;
}

button {
  font-family: inherit;
  font-size: 1em;
}

.inputs {
  vertical-align: bottom;
  display: flex;
  align-items: flex-end;
}

.errorText {
  color: rgb(189, 18, 18);
}
/*
    display: flex;
    align-items: flex-end;
    justify-content: center;
  */
