body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body {
  height: 100%;
  width: 100vw;
}

* {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.main {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-wrap: nowrap;

  gap: 0px 5px;
}

.header {
  font-size: 18px;

  z-index: 5;
  /*background-color: rgba(8, 8, 8, 0.514);*/
  /*  background-color: rgba(75, 158, 188, 0.94);*/
  background-color: rgba(37, 37, 37, 1);
  /* background-image: linear-gradient(rgba(49, 101, 148, 0.792), rgba(75, 158, 188, 0.94));*/
  /* filter: drop-shadow(6px 16px 30px rgb(232, 239, 243)) invert(5%);*/
  color: rgba(209, 213, 219, 1);
  right: 0vw;
  top: 0vh;
}

.footer {
  font-size: 18px;
  z-index: 5;
  background-color: rgba(8, 8, 8, 0.589);
  right: 0vw;
  top: 0vh;

  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));
}

.headerText {
  color: rgba(209, 213, 219, 1);
}

input[type='text'] {
  font-size: 16px;
}

input[type='password'] {
  font-size: 16px;
}

button {
  font-family: inherit;
  font-size: 1em;
}

.inputs {
  vertical-align: bottom;
  display: flex;
  align-items: center;
}

.inputBox {
  width: 250px;
}

.forgotLink {
  position: absolute;
  left: 50%;
  bottom: 20%;
  transform: translate(-50%, -50%);
}

/* SCROLLBAR*/
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(122, 119, 119);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(64, 117, 230, 0.479);
}
/* SCROLLBAR*/
